import React, {useEffect} from 'react';
import { useState, useRef } from 'react';
import './Home.scss'; // Import CSS file for styling
import prashastImage from '../../assets/images/Prashast_Name.png'
import EducationTab from '../EducationTab/EducationTab';
import HobbiesTab from '../Hobbies/Hobbies';
import MoreTab from '../MoreTab/MoreTab'
import { useHref } from 'react-router-dom';
import Lottie from 'lottie-web';


const Home = () => {
  const container = useRef(null)
  useEffect(() =>  {
    const instance=Lottie.loadAnimation({
        container: container.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: require('../../assets/animation/animation.json')
      });
      return () => instance.destroy();
    
  }, []);
  const [showMoreTab, setShowMoreTab] = useState(false);
  const [showEducationTab, setShowEducationTab] = useState(false);
  const [showHobbiesTab, setShowHobbiesTab] = useState(false);
  const handleCloseEducationTab = () => {
    setShowEducationTab(false);
  };

  const handleCloseHobbiesTab = () => {
    setShowHobbiesTab(false);
  };
  const handleToggleMoreTab = () => {
    setShowMoreTab(false);
  };
  return (
    <div className="container"  >
      <h1 className="name">
      <span>Hello, my name is</span>
        <img src={prashastImage} alt="Prashast Singh" className="prashast-image vibrating disappearing" />
        
      </h1>
      <p className="info">I'm a M.Sc. Computer Science student</p>
      <div className="animations">
        {/* Add your cool animations here */}
        <div className="animation animation1" onClick={() => {setShowEducationTab(true); setShowHobbiesTab(false);setShowHobbiesTab(false) } }>Education</div>
        <div className="animation animation2" onClick={()=> {setShowHobbiesTab(true); setShowEducationTab(false); setShowMoreTab(false) }}>Hobbies</div>
        <div className="animation animation3" onClick={()=>{setShowMoreTab(true); setShowEducationTab(false); setShowHobbiesTab(false)}}>More</div>
      </div>
      {showEducationTab && <EducationTab onClose={handleCloseEducationTab} />}
      {showHobbiesTab && <HobbiesTab onClose={handleCloseHobbiesTab} />}
      {showMoreTab && <MoreTab onClose={handleToggleMoreTab} />}
      <div className='animationContainer' ref={container}></div>
    </div>
  );
};

export default Home;
