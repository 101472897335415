import React from 'react';
import './Skill.scss'; 
import HtmlImage from '../../assets/images/htmlLogo.png'
import CssImage from '../../assets/images/css3d.webp'
import JavaScriptImage from '../../assets/images/Javascript3d.webp'
import ReactImage from '../../assets/images/react.webp'
import JavaLogo from '../../assets/images/java.png'
import SpringLogo from '../../assets/images/spring-boot-logo.png'
import SqlLogo from '../../assets/images/sql.webp'
import NodeLogo from '../../assets/images/node-js-icon-454x512-nztofx17.png'

const Skill = () => {
  const skillData = {
    frontend: {
      category: "Frontend Skills",
      skills: [
        {
          name: "HTML",
          image: HtmlImage,
        },
        {
          name: "CSS",
          image: CssImage,
        },
        {
          name: "JavaScript",
          image: JavaScriptImage,
        },
        {
          name: "React",
          image: ReactImage,
        },
      ],
    },
    backend: {
      category: "Backend Skills",
      skills: [
        {
          name: "Java",
          image: JavaLogo,
        },
        {
          name: "Node.js",
          image: NodeLogo,
        },
        {
          name: "Spring",
          image: SpringLogo,
        },
        {
          name: "SQL",
          image: SqlLogo,
        },
      ],
    },
  };

  return (
    <div className="skill">
    <div >
      <h2 className="category">{skillData.frontend.category}</h2>
      <div className="skills-container">
        {skillData.frontend.skills.map((skill, index) => (
          <img key={index} src={skill.image} alt={skill.name} className="logoImg" />
        ))}
      </div>
    </div>

    <div>
      <h2 className="category">{skillData.backend.category}</h2>
      <div className="skills-container">
        {skillData.backend.skills.map((skill, index) => (
          <img key={index} src={skill.image} alt={skill.name} className="logoImg" />
        ))}
      </div>
    </div>
  </div>
  );
};

// Helper function to get the image based on the skill name
const getSkillImage = (skill) => {
  switch (skill) {
    case 'HTML':
      return HtmlImage;
    case 'CSS':
      return CssImage;
    case 'JavaScript':
      return JavaScriptImage;
    case 'React':
      return ReactImage;
    case 'Java':
      return JavaLogo;
    case 'Spring':
      return SpringLogo;
    case 'SQL':
      return SqlLogo;
    case 'Node.js':
      return NodeLogo;
    default:
      return ''; // Default case if no matching skill image found
  }
};

export default Skill;


/*import React from 'react';
import './Skill.scss'; 
import HtmlImage from '../../assets/images/htmlLogo.png'
import CssImage from '../../assets/images/css3d.webp'
import JavaScriptImage from '../../assets/images/Javascript3d.webp'
import ReactImage from '../../assets/images/react.webp'
import JavaLogo from '../../assets/images/java.png'
import SpringLogo from '../../assets/images/spring-boot-logo.png'
import SqlLogo from '../../assets/images/sql.webp'
import NodeLogo from '../../assets/images/node-js-icon-454x512-nztofx17.png'
const Skill = ({ category, skills }) => {
  return (
    <div className="skill-category">
      <h2 className="category">FrontEnd Skills</h2>
      <div className="skills-container">
        {
          <div >
            <img src={HtmlImage} className='logoImg' />
            <img src={CssImage}  className='logoImg'/>
            <div>
              <img src={JavaScriptImage} className='logoImg' />
              <img src={ReactImage} className='logoImg' />
              </div>
              
             
          </div>
        }
      </div>
      <div>
              <h2 className="category">BackEnd Skills</h2>  
        </div>
        <div className="skills-container">
        {
          <div >
            <img src={JavaLogo} className='logoImg' />
            <img src={NodeLogo} className='logoImg' />
            
            <div>
              <img src={SpringLogo}  className='logoImg'/>
              <img src={SqlLogo} className='logoImg' />
              
              </div>
              
             
          </div>
        }
      </div>
    </div>
  );
};

export default Skill;*/
