import React from 'react';
import { useState } from 'react';
import './Project.scss';
import RestaurantImg from '../../assets/images/RestarauntApp.jpg'
import ExpenseImg from '../../assets/images/Expense Tracker.jpg'
import GroupChatImg from '../../assets/images/groupchat.webp'
import vmImg from '../../assets/images/Containers-Virtual-Machines.png'
import Description from '../ProjectDescription/Description';

const Project = () => {
    const [showDescriptionTab, setShowDescriptionTab] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);

    const handleCloseDescriptionTab = () => {
        setShowDescriptionTab(false);
      };
     
      const handleProjectClick = (project) => {
        setSelectedProject(project);
        setShowDescriptionTab(true);
      };

      const restaurantAppDetails = {
        title: " Restaurant Management App",
        description: "",
        githubLink: "   "
      }
      const expenseTracker = {
        title: "Expense Tracker",
        description: `Developed a user-friendly web application enabling sign-up and login functionalities for expense tracking.
        Implemented Razorpay payment gateway for secure premium membership purchases, offering weekly and monthly expense reports to premium users. Designed and integrated a leaderboard feature exclusively for premium members, showcasing the user with the highest expenses.
        Implemented an intuitive password reset mechanism, enabling users to
        securely request a personalized reset link, resulting in a 50% reduction in
        account lockouts and improved user satisfaction. Tech Stack: HTML, CSS, Javascript, Bootstrap, jQuery, Node.js, Express.js, AWS, MongoDb"`,
        githubLink: "https://github.com/prashast-singh/expense-tracker-backend"
      }
      const groupChat = {
        title: "Group Chat",
        description: `Real-Time Chat is powered by Socket.IO. is a web application that allows multiple users to have a private and public chat. This app allows one to one chat online and its fast and easy to use.
        Tech Stack: HTML, CSS, Javascript, Node.js, Express.js, Socket.io`,
        githubLink: "   "
      }

      const vmContainer  = {
        title: "Consolidation of Virtual Machines and Containers",
        description: " aaaa",
        githubLink: "   "
      }


  return (
    <div className="project">
      <h3>Personal Projects</h3>
      <div className='imageContainer'>
        <div>
          <div className='projectImage'>
            <img src={ExpenseImg} alt="Expense App" onClick={()=>{handleProjectClick(expenseTracker)}} />
            <p>Expense Tracker</p>
          </div>
          <div className='projectImage'>
            <img src={RestaurantImg} alt="Restraunt app" />
            <p>Restaurant Management App</p>
          </div>
        </div>
        <div>
          <div className='projectImage'>
            <img src={GroupChatImg} alt="Group Chat" onClick={()=>{handleProjectClick(groupChat)}}/>
            <p>Group Chat</p>
          </div>
          <div className='projectImage'>
            <img src={vmImg} alt="Consolidation of VM and Containers" />
            <p>Consolidation of VM and Containers</p>
          </div>
        </div>
      </div>
      {showDescriptionTab && <Description onClose={handleCloseDescriptionTab} project={selectedProject}  />}
    </div>
  );
};

export default Project;

/*import React, { useState } from 'react';
import './Project.scss';
import RestaurantImg from '../../assets/images/RestarauntApp.jpg'
import ExpenseImg from '../../assets/images/Expense Tracker.jpg'
import GroupChatImg from '../../assets/images/groupchat.webp'
import vmImg from '../../assets/images/Containers-Virtual-Machines.png'

const Project = () => {

  return (
    <div className="project">
        <h3>Personal Projects</h3>
        <div className='imageContainer'>
        <div>
            <div className='projectImage'><img src={RestaurantImg} alt={"project"}  /><p>Restaurant Management App</p></div>
            <div className='projectImage'><img src={ExpenseImg} alt={"project"}  /><p>Expense Tracker</p></div>
        </div>
        <div>
            <div className='projectImage'><img src={GroupChatImg} alt={"project"}  /><p>Group Chat</p></div>
            <div className='projectImage'><img src={vmImg} alt={"project"}  /><p>Consolidation of VM and Containers</p></div>
        </div>
        </div>
    </div>
  );
};

export default Project; */
