import React from 'react';
import './EducationTab.scss'; // Import CSS file for styling

const EducationTab = ({ onClose }) => {
  return (
    <div className="education-tab">
      <div className="header">
        <h2>Education</h2>
        <button className="close-button" onClick={onClose}>X</button>
      </div>
      <div className="content">
        <table>
          <thead>
            <tr>
              <th>Degree</th>
              <th>Year</th>
              <th>Institution</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>M.Sc. Computer Science</td>
              <td>2023-present</td>
              <td>Philipps University Of Marburg</td>
            </tr>
            <tr>
              <td>B.E. Information Science and Engineering</td>
              <td>2018-2022</td>
              <td>Visvesvaraya Technological University</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EducationTab;
