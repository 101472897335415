import './ContactLayout.scss';
import Sidebar from '../Sidebar';
import ContactForm from '../ContactComponent/Contact';
const ContactLayout = () => {
    
    return (
      <div className="about-container">
        <Sidebar />
        <div className='skill-project-container'>
            <div className='frontendSkill'><ContactForm/></div>
        </div>
        
      <div>
           
        
      </div>
      </div>
    );
  };

  export default ContactLayout;