import './App.scss';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import About from './components/AboutLayout/About';
import ContactLayout from './components/ContactLayout/ContactLayout';
function App() {
  return (
    <>
    <Routes>
      <Route path='/' element={<Layout></Layout>}></Route>
      <Route path='/about' element={<About/>}></Route>
      <Route path='/contact' element={<ContactLayout/>}></Route>
    </Routes>
    </>
  );
}

export default App;
