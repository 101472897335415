import './About.scss';
import Sidebar from '../Sidebar';
import Skill from '../Skill/Skill';
import Project from '../ProjectComponent/Project';

const About = () => {
    
    return (
      <div className="about-container">
        <Sidebar />
        <div className='skill-project-container'>
            <div className='frontendSkill'><Skill/></div>
            <div className='frontendSkill'><Project/></div>
        </div>
        
      <div>
           
        
      </div>
      </div>
    );
  };
  
  export default About;
