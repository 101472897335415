import React from 'react';
import './Description.scss';

const Description = ({ project, onClose }) => {
  return (
    <div className="description">
      <div className="header">
        <h2>Project Details</h2>
        <button className="close-button" onClick={onClose}>X</button>
      </div>
      <div className="content">
        <h3>{project.title}</h3>
        <p>{project.description}</p>
        <a href={project.githubLink} target="_blank" rel="noopener noreferrer">Github Link</a>
      </div>
    </div>
  );
};

export default Description;
