// MoreTab.js

import React from 'react';
import './MoreTab.scss';
import { useState } from 'react';

const MoreTab = ({ onClose }) => {
    //////for dragging
    
    ////////
  return (
    <div className="more-tab">
      <div className="header">
        <h2>Work Experience</h2>
        <button className="close-button" onClick={onClose}>X</button>
      </div>
      <div className="content">
        <div className="experience">
          <h3>Web Development Trainee</h3>
          <p>Mar 2023 - Aug 2023</p>
          <p>Sharpener Remote</p>
          <ul>
            <li>Orchestrated the end-to-end design and development of fully responsive and functional web applications, ensuring seamless user experiences across diverse devices.</li>
            <li>Played a pivotal role in leading a learner group, providing mentorship to new joiners and fostering a collaborative learning environment.</li>
          </ul>
        </div>
        <div className="experience">
          <h3>Software Developer Intern</h3>
          <p>Sep 2022 - Feb 2023</p>
          <p>Desi Qna Remote</p>
          <ul>
            <li>Design and development of a fully responsive webpage dedicated to tutorials, ensuring an optimal user experience across various devices.</li>
            <li>Integrated Google’s Recaptcha as a vital layer of protection, significantly enhancing the website’s security posture against malicious activities.</li>
            <li>Achieved a remarkable 50% reduction in fake bots and posts following the seamless integration of Recaptcha.</li>
          </ul>
        </div>
        <div className="experience">
          <h3>Summer Intern</h3>
          <p>Oct 2020 - Nov 2020</p>
          <p>EICT, Indian Institute of Technology, Kanpur Remote</p>
          <ul>
            <li>Proficiently conducted comprehensive data analysis, employing advanced techniques in data analytics and visualization using Python to derive meaningful insight.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MoreTab;
