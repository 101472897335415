import './index.scss';
import Sidebar from '../Sidebar';
import Home from '../Home/Home'
const Layout = () => {
    return (
      <div className="layout">
        <Sidebar />
        <Home />
      </div>
    );
  };
  
  export default Layout;

