import React from 'react';
import './HobbiesTab.scss'; // Import CSS file for styling

const HobbiesTab = ({ onClose }) => {
  return (
    <div className="hobbies-tab slide-in">
      <div className="header">
        <h2>Hobbies</h2>
        <button className="close-button" onClick={onClose}>X</button>
      </div>
      <div className="content">
        <ul>
          <li>Cricket</li>
          <li>Hiking</li>
          <li>Beach Camping</li>
        </ul>
      </div>
    </div>
  );
};

export default HobbiesTab;
