/*import { Link, NavLink } from 'react-router-dom';
import './index.scss';
import LogoS from '../../assets/images/pic.jpg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faEnvelope, faHome, faUser } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Sidebar = ()=>{

   return <><div className='nav-bar'>
   <Link to="/">
   <img className='logo' src={LogoS} alt='logo'></img>

   </Link>
   <nav>
    <NavLink exact ="true" activeclassname='active' to='/'>
        <FontAwesomeIcon icon={faHome} color='#4d4d4e'/>
    </NavLink>
    <NavLink exact ="true" activeclassname='active' className='about-link' to='/about'>
        <FontAwesomeIcon icon={faUser} color='#4d4d4e'/>
    </NavLink>
    <NavLink exact ="true" activeclassname='active' className='contact-link' to='/contact'>
        <FontAwesomeIcon icon={faEnvelope} color='#4d4d4e'/>
    </NavLink>

    </nav>
    <ul>
        <li>
            <a 
            target='_blank'
            rel='noreferrer'
            href='https://www.linkedin.com/in/prashast-singh-4519a31ab/'
            >
            <FontAwesomeIcon icon={faLinkedin} color='#4d4d4e'/>
            </a>
        </li>
        <li>
            <a 
            target='_blank'
            rel='noreferrer'
            href='https://github.com/prashast-singh'
            >
            <FontAwesomeIcon icon={faGithub} color='#4d4d4e'/>
            </a>
        </li>

        <li>
            <a 
            target='_blank'
            rel='noreferrer'
            href='https://leetcode.com/prashast_singh/'
            >
            <FontAwesomeIcon icon={faCode} color='#4d4d4e'/>
            </a>
        </li>



    </ul>
  </div></>
}

export default Sidebar
*/
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faEnvelope, faHome, faUser } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './index.scss';
import LogoS from '../../assets/images/pic.jpg';

const Sidebar = () => {
    return (
        <div className='nav-bar'>
            <Link to="/">
                <img className='logo' src={LogoS} alt='logo' />
            </Link>
            <nav>
                <NavLink exact activeClassName='active' to='/'>
                    <FontAwesomeIcon icon={faHome} color='#4d4d4e' />
                </NavLink>
                <NavLink exact activeClassName='active' className='about-link' to='/about'>
                    <FontAwesomeIcon icon={faUser} color='#4d4d4e' />
                </NavLink>
                <NavLink exact activeClassName='active' className='contact-link' to='/contact'>
                    <FontAwesomeIcon icon={faEnvelope} color='#4d4d4e' />
                </NavLink>
            </nav>
            <ul>
                <li>
                    <a
                        target='_blank'
                        rel='noreferrer'
                        href='https://www.linkedin.com/in/prashast-singh-4519a31ab/'
                    >
                        <FontAwesomeIcon icon={faLinkedin} color='#4d4d4e' />
                    </a>
                </li>
                <li>
                    <a
                        target='_blank'
                        rel='noreferrer'
                        href='https://github.com/prashast-singh'
                    >
                        <FontAwesomeIcon icon={faGithub} color='#4d4d4e' />
                    </a>
                </li>
                <li>
                    <a
                        target='_blank'
                        rel='noreferrer'
                        href='https://leetcode.com/prashast_singh/'
                    >
                        <FontAwesomeIcon icon={faCode} color='#4d4d4e' />
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default Sidebar;
